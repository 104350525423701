import { MicrowdAPI } from "@/firebase";
import lodash from "lodash";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  projects: [],
  project: {},
  totalCountProjects: 0,
  testsCalendarPayments: [],
  testCalendarPayments: {},
  totalCountTestsCalendarPayments: 0,
  comments: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Projects
  async getProjects({ commit }, data) {
    commit("SET_PROJECTS", []);

    try {
      const response = await elasticSearchApi.post("/project", data);

      commit("SET_TOTAL_COUNT_PROJECTS", response.data.total);
      commit("SET_PROJECTS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getProject(_, data) {
    try {
      const response = await elasticSearchApi.post("/project", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async editProject(_, { ...project }) {
    // Transform Array of investments and repayments to Object and delete the id param
    if (project.investments) {
      const arr_investments = lodash.keyBy(project.investments, "id");
      for (const key in arr_investments) {
        delete arr_investments[key].id;
      }
      project.investments = arr_investments;
    }

    if (project.repayments) {
      const arr_repayments = lodash.keyBy(project.repayments, "id");
      for (const key in arr_repayments) {
        delete arr_repayments[key].id;
      }
      project.repayments = arr_repayments;
    }

    await MicrowdAPI({ action: "editProject", data: project });
  },

  // Insert cheque
  async insertCheque(_, params) {
    await MicrowdAPI({ action: "insertCheque", data: params });
  },

  // Edit cheque
  async editCheque(_, params) {
    await MicrowdAPI({ action: "editChequeDate", data: params });
  },

  // Add investment to project
  async investProject(_, { project_id, investor_id, amount }) {
    await MicrowdAPI({ action: "investProject", data: { project_id, investor_id, amount } });
  },

  // Add investment to multiple projects
  async investMultipleProjects(_, investments) {
    await MicrowdAPI({ action: "investMultipleProjects", data: investments });
  },

  // Add repayment to project
  async addRepaymentProject(_, data) {
    const response = await MicrowdAPI({ action: "insertRepayment", data });
    return response.data.data;
  },

  // Edit repayment
  async editRepayment(_, data) {
    await MicrowdAPI({ action: "editRepayment", data });
  },

  // Delete repayment of project
  async deleteProjectRepayment(_, repayment_id) {
    await MicrowdAPI({ action: "deleteRepayment", data: repayment_id });
  },

  // Consolidate repayment of project changing status to reviewed
  async changeRepaymentToReviewed(_, repayment_id) {
    await MicrowdAPI({ action: "changeRepaymentToReviewed", data: repayment_id });
  },
  // Return reviewed repayment to created status
  async returnRepaymentToCreatedStatus(_, repayment_id) {
    await MicrowdAPI({ action: "returnRepaymentToCreatedStatus", data: repayment_id });
  },

  // Add comment to project
  async createProjectComment(_, data) {
    await MicrowdAPI({ action: "createProjectComment", data });
  },

  // Edit project comment
  async editProjectComment(_, data) {
    await MicrowdAPI({ action: "editProjectComment", data });
  },

  // Delete project comment
  async deleteProjectComment(_, data) {
    await MicrowdAPI({ action: "deleteComment", data });
  },

  // Change if project is visble on website or not
  async toggleVisibility(_, project_id) {
    await MicrowdAPI({ action: "changeProjectVisibility", data: project_id });
  },

  // Change amount requested
  async changeProjectAmountRequested(_, { project_id, amount_requested }) {
    await MicrowdAPI({ action: "changeProjectAmountRequested", data: { project_id, amount_requested } });
  },

  // Change project technician
  async changeTechnician(_, data) {
    await MicrowdAPI({ action: "changeProjectTechnician", data });
  },

  // Change project status to investable
  async changeToInvestable(_, project_id) {
    await MicrowdAPI({ action: "changeProjectToInvestable", data: project_id });
  },

  // Change if project is to bag or not
  async toggleToBag(_, project_id) {
    await MicrowdAPI({ action: "changeProjectToBag", data: project_id });
  },

  // Get the amount to prepaid the whole project
  async getAmountPrepaidProject(_, data) {
    const response = await MicrowdAPI({ action: "getAmountPrepaidProject", data });
    return response.data.data;
  },

  // Get the amount to prepaid the whole test
  async getAmountPrepaidTestCalendarPayments(_, data) {
    const response = await MicrowdAPI({ action: "getAmountPrepaidTestCalendarPayments", data });
    return response.data.data;
  },

  async changeToCondoned(_, condoned_params) {
    await MicrowdAPI({ action: "changeProjectToCondoned", data: condoned_params });
  },

  async changeToDefendant(_, project_id) {
    await MicrowdAPI({ action: "changeProjectToDefendant", data: project_id });
  },

  async restructureProject(_, data) {
    await MicrowdAPI({ action: "restructureProject", data });
  },

  async penalizeProject(_, data) {
    await MicrowdAPI({ action: "penalizeProject", data });
  },

  async returnProjectToApproved(_, project_id) {
    await MicrowdAPI({ action: "returnProjectToApprovedStatus", data: project_id });
  },

  async cancelProject(_, cancel_params) {
    await MicrowdAPI({ action: "cancelProject", data: cancel_params });
  },

  async editProjectMoratoriums(_, { id, moratoriums }) {
    await MicrowdAPI({ action: "editProjectMoratoriums", data: { id, moratoriums } });
  },

  async recalculateCalendarPayments(_, data) {
    await MicrowdAPI({ action: "recalculateCalendarPayments", data });
  },

  async getRepayments(_, data) {
    try {
      const response = await elasticSearchApi.post("/repayment", data);
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  // -------------------------------------- TESTS CALENDAR PAYMENTS --------------------------------------
  async getTestsCalendarPayments({ commit }, data) {
    commit("SET_TESTS_CALENDAR_PAYMENTS", []);

    try {
      const response = await elasticSearchApi.post("/testing", data);

      commit("SET_TOTAL_COUNT_TESTS_CALENDAR_PAYMENTS", response.data.total);
      commit("SET_TESTS_CALENDAR_PAYMENTS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async createTestCalendarPayments(_, data) {
    await MicrowdAPI({ action: "createTestCalendarPayments", data });
  },

  async editTestCalendarPayments(_, data) {
    await MicrowdAPI({ action: "editTestCalendarPayments", data });
  },

  async editTestCalendarPaymentsRepayments(_, data) {
    await MicrowdAPI({ action: "editTestCalendarPaymentsRepayments", data });
  },

  async recalculateTestCalendarPayments(_, data) {
    await MicrowdAPI({ action: "recalculateTestCalendarPayments", data });
  },

  async editTestCalendarPaymentsMoratoriums(_, { id, moratoriums }) {
    await MicrowdAPI({ action: "editTestCalendarPaymentsMoratoriums", data: { id, moratoriums } });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },

  SET_PROJECT(state, project) {
    state.project = project;
  },

  SET_TOTAL_COUNT_PROJECTS(state, totalCount) {
    state.totalCountProjects = totalCount;
  },

  SET_TESTS_CALENDAR_PAYMENTS(state, testsCalendarPayments) {
    state.testsCalendarPayments = testsCalendarPayments;
  },

  SET_TEST_CALENDAR_PAYMENTS(state, testCalendarPayments) {
    state.testCalendarPayments = testCalendarPayments;
  },

  SET_TOTAL_COUNT_TESTS_CALENDAR_PAYMENTS(state, totalCount) {
    state.totalCountTestsCalendarPayments = totalCount;
  },

  SET_COMMENTS(state, comments) {
    state.comments = comments;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
